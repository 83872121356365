*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Open Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 425px) {
  .container {
    max-width: 425px;
  }
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (width >= 1920px) {
  .container {
    max-width: 1920px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-4 {
  left: 1rem;
}

.right-0 {
  right: 0;
}

.right-3 {
  right: .75rem;
}

.right-4 {
  right: 1rem;
}

.right-\[2px\] {
  right: 2px;
}

.top-0 {
  top: 0;
}

.top-3 {
  top: .75rem;
}

.top-\[2px\] {
  top: 2px;
}

.top-\[72px\] {
  top: 72px;
}

.top-\[calc\(100\%\+16px\)\] {
  top: calc(100% + 16px);
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-full {
  grid-column: 1 / -1;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-\[3px\] {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-\[60px\] {
  margin-left: 60px;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-\[9px\] {
  margin-top: 9px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-\[4\/1\] {
  aspect-ratio: 4;
}

.h-10 {
  height: 2.5rem;
}

.h-3 {
  height: .75rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[130px\] {
  height: 130px;
}

.h-\[148px\] {
  height: 148px;
}

.h-\[149px\] {
  height: 149px;
}

.h-\[14px\] {
  height: 14px;
}

.h-\[178px\] {
  height: 178px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[28px\] {
  height: 28px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[43px\] {
  height: 43px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[58px\] {
  height: 58px;
}

.h-\[66px\] {
  height: 66px;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[82px\] {
  height: 82px;
}

.h-\[95px\] {
  height: 95px;
}

.h-full {
  height: 100%;
}

.max-h-\[calc\(100\%_-_32px\)\] {
  max-height: calc(100% - 32px);
}

.max-h-\[calc\(100\%_-_66px\)\] {
  max-height: calc(100% - 66px);
}

.min-h-\[30px\] {
  min-height: 30px;
}

.min-h-\[66px\] {
  min-height: 66px;
}

.\!w-\[92px\] {
  width: 92px !important;
}

.\!w-\[calc\(100\%_-_1px\)\] {
  width: calc(100% - 1px) !important;
}

.\!w-full {
  width: 100% !important;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-\[172px\] {
  width: 172px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[24px\] {
  width: 24px;
}

.w-\[32px\] {
  width: 32px;
}

.w-\[36px\] {
  width: 36px;
}

.w-\[calc\(100\%_-_32px\)\] {
  width: calc(100% - 32px);
}

.w-full {
  width: 100%;
}

.min-w-\[302px\] {
  min-width: 302px;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-\[2188px\] {
  max-width: 2188px;
}

.max-w-\[78rem\] {
  max-width: 78rem;
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-0 {
  flex-basis: 0;
}

.basis-4 {
  flex-basis: 1rem;
}

.basis-\[74px\] {
  flex-basis: 74px;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 32px #0000001a;
  }

  70% {
    box-shadow: 0 0 28px -5px #0003;
  }

  100% {
    box-shadow: 0 0 32px #0000001a;
  }
}

.animate-shadow-pulse {
  animation: .2s ease-in-out shadow-pulse;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.\!flex-row {
  flex-direction: row !important;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.\!items-start {
  align-items: flex-start !important;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.\!gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[60px\] {
  gap: 60px;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.self-start {
  align-self: flex-start;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.\!rounded {
  border-radius: .25rem !important;
}

.\!rounded-full {
  border-radius: 9999px !important;
}

.\!rounded-sm {
  border-radius: .125rem !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-t-\[\.5rem\] {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-none {
  border-style: none;
}

.\!border-lw-disabled {
  --tw-border-opacity: 1 !important;
  border-color: rgb(240 241 249 / var(--tw-border-opacity, 1)) !important;
}

.border-lw-disabled {
  --tw-border-opacity: 1;
  border-color: rgb(240 241 249 / var(--tw-border-opacity, 1));
}

.border-lw-ui-border {
  --tw-border-opacity: 1;
  border-color: rgb(223 223 223 / var(--tw-border-opacity, 1));
}

.border-lw-ui-border-active {
  --tw-border-opacity: 1;
  border-color: rgb(19 117 57 / var(--tw-border-opacity, 1));
}

.border-lw-ui-border-disabled {
  --tw-border-opacity: 1;
  border-color: rgb(117 117 117 / var(--tw-border-opacity, 1));
}

.border-lw-ui-border-hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.border-lw-ui-dark {
  --tw-border-opacity: 1;
  border-color: rgb(17 16 16 / var(--tw-border-opacity, 1));
}

.border-lw-ui-purple-dark {
  --tw-border-opacity: 1;
  border-color: rgb(86 71 253 / var(--tw-border-opacity, 1));
}

.border-transparent {
  border-color: #0000;
}

.border-l-lw-ui-border {
  --tw-border-opacity: 1;
  border-left-color: rgb(223 223 223 / var(--tw-border-opacity, 1));
}

.border-t-lw-ui-border {
  --tw-border-opacity: 1;
  border-top-color: rgb(223 223 223 / var(--tw-border-opacity, 1));
}

.\!bg-lw-disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(240 241 249 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-lw-ui-border {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(223 223 223 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}

.bg-lw-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-lw-blue-dark\/75 {
  background-color: #15151dbf;
}

.bg-lw-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(240 241 249 / var(--tw-bg-opacity, 1));
}

.bg-lw-off-white {
  --tw-bg-opacity: 1;
  background-color: rgb(252 251 252 / var(--tw-bg-opacity, 1));
}

.bg-lw-ui-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(17 16 16 / var(--tw-bg-opacity, 1));
}

.bg-lw-ui-green {
  --tw-bg-opacity: 1;
  background-color: rgb(20 117 56 / var(--tw-bg-opacity, 1));
}

.bg-lw-ui-light {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

.bg-lw-ui-purple-light {
  --tw-bg-opacity: 1;
  background-color: rgb(236 235 255 / var(--tw-bg-opacity, 1));
}

.bg-lw-ui-warning {
  --tw-bg-opacity: 1;
  background-color: rgb(223 51 22 / var(--tw-bg-opacity, 1));
}

.bg-lw-ui-warning-light {
  --tw-bg-opacity: 1;
  background-color: rgb(255 148 146 / var(--tw-bg-opacity, 1));
}

.bg-lw-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-white {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-40\% {
  --tw-gradient-via-position: 40%;
}

.to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.fill-lw-text-disabled {
  fill: #757575;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.\!p-\[6px\] {
  padding: 6px !important;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[6px\] {
  padding: 6px;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[11px\] {
  padding-top: 11px;
  padding-bottom: 11px;
}

.py-\[150px\] {
  padding-top: 150px;
  padding-bottom: 150px;
}

.py-\[15px\] {
  padding-top: 15px;
  padding-bottom: 15px;
}

.\!pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pr-\[11px\] {
  padding-right: 11px;
}

.pr-\[15px\] {
  padding-right: 15px;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-\[2px\] {
  padding-top: 2px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-top {
  vertical-align: top;
}

.font-roobert {
  font-family: Roobert, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.\!text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-none {
  line-height: 1;
}

.tracking-wide {
  letter-spacing: .025em;
}

.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1)) !important;
}

.\!text-lw-text-disabled {
  --tw-text-opacity: 1 !important;
  color: rgb(117 117 117 / var(--tw-text-opacity, 1)) !important;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-lw-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-lw-text-disabled {
  --tw-text-opacity: 1;
  color: rgb(117 117 117 / var(--tw-text-opacity, 1));
}

.text-lw-ui-green {
  --tw-text-opacity: 1;
  color: rgb(20 117 56 / var(--tw-text-opacity, 1));
}

.text-lw-ui-purple-dark {
  --tw-text-opacity: 1;
  color: rgb(86 71 253 / var(--tw-text-opacity, 1));
}

.text-lw-white, .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.decoration-dotted {
  text-decoration-style: dotted;
}

.underline-offset-2 {
  text-underline-offset: 2px;
}

.opacity-60 {
  opacity: .6;
}

.\!shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-metal {
  --tw-shadow: 0px 0px 32px 0px #0000001a;
  --tw-shadow-colored: 0px 0px 32px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-toolTip {
  --tw-shadow: 0px 0px .5px 0px #00000040, 0px 2px 4px 0px #00000026;
  --tw-shadow-colored: 0px 0px .5px 0px var(--tw-shadow-color), 0px 2px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\!outline-none {
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.\!outline {
  outline-style: solid !important;
}

.outline {
  outline-style: solid;
}

.\!outline-1 {
  outline-width: 1px !important;
}

.outline-1 {
  outline-width: 1px;
}

.outline-offset-0 {
  outline-offset: 0px;
}

.\!outline-lw-ui-border-active {
  outline-color: #137539 !important;
}

.\!outline-transparent {
  outline-color: #0000 !important;
}

.outline-lw-ui-border-active {
  outline-color: #137539;
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-75 {
  transition-duration: 75ms;
}

.\@container {
  container-type: inline-size;
}

.\[appearance\:none\] {
  appearance: none;
}

.\[appearance\:textfield\] {
  appearance: textfield;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:inline-block:before {
  content: var(--tw-content);
  display: inline-block;
}

.before\:pr-2:before {
  content: var(--tw-content);
  padding-right: .5rem;
}

.before\:align-middle:before {
  content: var(--tw-content);
  vertical-align: middle;
}

.before\:content-\[\"•\"\]:before {
  --tw-content: "•";
  content: var(--tw-content);
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:left-\[3px\]:after {
  content: var(--tw-content);
  left: 3px;
}

.after\:left-\[4px\]:after {
  content: var(--tw-content);
  left: 4px;
}

.after\:top-\[3px\]:after {
  content: var(--tw-content);
  top: 3px;
}

.after\:top-\[4px\]:after {
  content: var(--tw-content);
  top: 4px;
}

.after\:h-\[12px\]:after {
  content: var(--tw-content);
  height: 12px;
}

.after\:h-\[8px\]:after {
  content: var(--tw-content);
  height: 8px;
}

.after\:w-\[12px\]:after {
  content: var(--tw-content);
  width: 12px;
}

.after\:w-\[8px\]:after {
  content: var(--tw-content);
  width: 8px;
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:bg-white:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:content-\[\"\"\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.even\:bg-gray-100:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.checked\:border-lw-ui-border-hover:checked {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.checked\:text-lw-ui-border-hover:checked {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.focus-within\:outline:focus-within {
  outline-style: solid;
}

.focus-within\:outline-1:focus-within {
  outline-width: 1px;
}

.focus-within\:outline-offset-0:focus-within {
  outline-offset: 0px;
}

.focus-within\:outline-lw-black:focus-within {
  outline-color: #000;
}

.focus-within\:outline-lw-ui-green:focus-within {
  outline-color: #147538;
}

.hover\:border-lw-black:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.hover\:border-lw-disabled:hover {
  --tw-border-opacity: 1;
  border-color: rgb(240 241 249 / var(--tw-border-opacity, 1));
}

.hover\:border-lw-ui-border-hover:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.hover\:bg-lw-ui-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(17 16 16 / var(--tw-bg-opacity, 1));
}

.hover\:bg-lw-ui-green-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(12 82 56 / var(--tw-bg-opacity, 1));
}

.hover\:bg-lw-ui-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.hover\:text-black:hover, .hover\:text-lw-black:hover, .hover\:text-lw-text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:\!shadow-none:hover {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:outline:hover {
  outline-style: solid;
}

.hover\:outline-1:hover {
  outline-width: 1px;
}

.hover\:outline-offset-0:hover {
  outline-offset: 0px;
}

.hover\:\!outline-transparent:hover {
  outline-color: #0000 !important;
}

.hover\:outline-lw-black:hover {
  outline-color: #000;
}

.focus\:border-lw-ui-border-hover:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.focus\:bg-lw-ui-light:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

.focus\:outline:focus {
  outline-style: solid;
}

.disabled\:border-lw-disabled:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(240 241 249 / var(--tw-border-opacity, 1));
}

.disabled\:bg-lw-disabled:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(240 241 249 / var(--tw-bg-opacity, 1));
}

.disabled\:text-lw-text-disabled:disabled {
  --tw-text-opacity: 1;
  color: rgb(117 117 117 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:bg-lw-ui-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(17 16 16 / var(--tw-bg-opacity, 1));
}

.group:hover .group-hover\:fill-lw-white {
  fill: #fff;
}

.group:disabled .group-disabled\:\!bg-lw-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}

.group:disabled .group-disabled\:\!fill-lw-text-disabled {
  fill: #757575 !important;
}

.group\/body.noscroll .group-\[\.noscroll\]\/body\:h-\[calc\(100dvh-125px\)\] {
  height: calc(100dvh - 125px);
}

.group\/body.noscroll .group-\[\.noscroll\]\/body\:overflow-y-scroll {
  overflow-y: scroll;
}

.peer:checked ~ .peer-checked\:bg-lw-ui-green {
  --tw-bg-opacity: 1;
  background-color: rgb(20 117 56 / var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:after\:translate-x-\[10px\]:after {
  content: var(--tw-content);
  --tw-translate-x: 10px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:translate-x-\[16px\]:after {
  content: var(--tw-content);
  --tw-translate-x: 16px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.peer:focus ~ .peer-focus\:ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.peer:focus ~ .peer-focus\:ring-lw-ui-border-disabled {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(117 117 117 / var(--tw-ring-opacity, 1));
}

.peer:focus ~ .peer-focus\:ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

@container (width >= 12rem) {
  .\@\[12rem\]\:flex-row {
    flex-direction: row;
  }

  .\@\[12rem\]\:items-center {
    align-items: center;
  }
}

@container (width >= 15rem) {
  .\@\[15rem\]\:flex-row {
    flex-direction: row;
  }

  .\@\[15rem\]\:items-center {
    align-items: center;
  }
}

.dark\:text-gray-300:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

@media (width >= 640px) {
  .sm\:not-sr-only {
    clip: auto;
    white-space: normal;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .sm\:order-last {
    order: 9999;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:\!w-\[160px\] {
    width: 160px !important;
  }

  .sm\:w-6 {
    width: 1.5rem;
  }

  .sm\:grow-0 {
    flex-grow: 0;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-0 {
    gap: 0;
  }

  .sm\:gap-2 {
    gap: .5rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:border-l {
    border-left-width: 1px;
  }

  .sm\:border-l-lw-ui-border {
    --tw-border-opacity: 1;
    border-left-color: rgb(223 223 223 / var(--tw-border-opacity, 1));
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (width >= 768px) {
  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:scale-\[1\.3\] {
    --tw-scale-x: 1.3;
    --tw-scale-y: 1.3;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }
}

@media (width >= 1024px) {
  .lg\:sticky {
    position: sticky;
  }

  .lg\:top-0 {
    top: 0;
  }

  .lg\:order-none {
    order: 0;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:-mr-8 {
    margin-right: -2rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[105px\] {
    height: 105px;
  }

  .lg\:h-\[155px\] {
    height: 155px;
  }

  .lg\:h-\[162px\] {
    height: 162px;
  }

  .lg\:h-\[226px\] {
    height: 226px;
  }

  .lg\:h-\[75px\] {
    height: 75px;
  }

  .lg\:h-\[77px\] {
    height: 77px;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:max-h-\[68vh\] {
    max-height: 68vh;
  }

  .lg\:\!w-full {
    width: 100% !important;
  }

  .lg\:w-\[360px\] {
    width: 360px;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:max-w-\[1280px\] {
    max-width: 1280px;
  }

  .lg\:grow {
    flex-grow: 1;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:overflow-y-auto {
    overflow-y: auto;
  }

  .lg\:rounded-md {
    border-radius: .375rem;
  }

  .lg\:border-0 {
    border-width: 0;
  }

  .lg\:\!p-4 {
    padding: 1rem !important;
  }

  .lg\:p-2 {
    padding: .5rem;
  }

  .lg\:p-3 {
    padding: .75rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pr-2 {
    padding-right: .5rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pt-\[150px\] {
    padding-top: 150px;
  }

  .lg\:pt-\[160px\] {
    padding-top: 160px;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:shadow-metal {
    --tw-shadow: 0px 0px 32px 0px #0000001a;
    --tw-shadow-colored: 0px 0px 32px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group\/body.noscroll .group-\[\.noscroll\]\/body\:lg\:\!h-screen {
    height: 100vh !important;
  }
}

@media (width >= 1280px) {
  .xl\:order-last {
    order: 9999;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:mr-4 {
    margin-right: 1rem;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:\!w-\[160px\] {
    width: 160px !important;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:grow {
    flex-grow: 1;
  }

  .xl\:grow-0 {
    flex-grow: 0;
  }

  .xl\:basis-\[306px\] {
    flex-basis: 306px;
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:items-start {
    align-items: flex-start;
  }

  .xl\:gap-6 {
    gap: 1.5rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:w-\[480px\] {
    width: 480px;
  }

  .\32 xl\:max-w-\[75\%\] {
    max-width: 75%;
  }

  .\32 xl\:\!p-4 {
    padding: 1rem !important;
  }
}

@media (width >= 1920px) {
  .\33 xl\:gap-16 {
    gap: 4rem;
  }
}

@media (width >= 1024px) and (height <= 900px) {
  .lg-reduced-height\:pt-\[115px\] {
    padding-top: 115px;
  }

  .lg-reduced-height\:pt-\[160px\] {
    padding-top: 160px;
  }
}

.\[\&\:\:-webkit-inner-spin-button\]\:appearance-none::-webkit-inner-spin-button {
  appearance: none;
}

.\[\&\:\:-webkit-outer-spin-button\]\:appearance-none::-webkit-outer-spin-button {
  appearance: none;
}
/*# sourceMappingURL=index.5f37d632.css.map */
